var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"server-items-length":_vm.itemTotal,"options":_vm.options,"footer-props":{ 'items-per-page-options': [5, 10, 15, 20, 25] },"loading":_vm.loading,"loading-text":"Loading scans..."},on:{"update:options":function (val) { return _vm.$emit('update', val); }},scopedSlots:_vm._u([{key:"item.inDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDate(item.in.date)))]}},{key:"item.outDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.out && item.out.date ? _vm.stringFormatDate(item.out.date) : (item.status === 'R' ? '***No Clock-out***' : '**Clocked-in ' + _vm.getTimeDiff(new Date(), item.in.date))))]}},{key:"item.hours",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatHours(item.hours, item.in.date)))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.status === 'P' && !('out' in item) ? 'I' : item.status))])]}}],null,true)},[(item.status === 'R')?_c('span',[_vm._v("Rejected: "+_vm._s(item.reason))]):(item.status === 'C')?_c('span',[_vm._v("Completed (time entered into Banner)")]):(!('out' in item))?_c('span',[_vm._v("Incomplete (has not yet clocked out)")]):(item.status === 'P')?_c('span',[_vm._v("Pending (has not been loaded into Banner)")]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }